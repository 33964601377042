

import {Component, Prop, Vue, Watch} from "vue-property-decorator";

export interface selectable {
  text: string;
  value: string;
}

@Component({})
export default class EnumPicker extends Vue {
  @Prop() label!: string;
  @Prop({default:'Giriniz'}) placeHolder !: string;
  @Prop() value!: any;
  @Prop() enum!: any;
  @Prop({default: true}) clearable!: boolean;
  @Prop({default: false}) disabled!: boolean;
  @Prop() labels!: any;
  @Prop() defaultFirst!: any;
  @Prop() color!: string;
  @Prop() hideDetails!: string | boolean;
  @Prop() dense!: string | boolean;
  @Prop() multiple!: string|boolean;
  @Prop() rules!: string;
  @Prop() description!: any;

  localValue = this.value ? this.value : null;
  items: Array<selectable> = [];

  get isDefaultFirst() {
    return this.defaultFirst || this.defaultFirst === "";
  }

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }

  get isDense() {
    return this.dense || this.dense === "";
  }
  get isMultiple() {
    return this.multiple || this.multiple === "";
  }

  mounted() {
    this.onEnumChange();
  }

  @Watch('value')
  onValueChange() {
    this.localValue = this.value ? this.value : null;
  }

  @Watch('enum')
  onEnumChange() {
    if (this.enum) {
      this.items = Object.keys(this.enum).map((key) => {
        return <selectable>{
          text: this.labels != null ? this.labels[key] : this.enum[key],
          value: this.enum[key],
        }
      });
    }

    if (!this.value && this.isDefaultFirst) {
      this.localValue = this.items[0]?.value;
      this.input();
    }
  }

  input() {
    this.$emit('input', this.localValue);
  }
}
